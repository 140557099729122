import {useContext, useRef, useState} from 'react';

import classNames from 'classnames';
import {observer} from "mobx-react";

import {buildCssUrlString} from "../../../utils/StringUtilities";
import ClickableLink from "../../utilities/ClickableLink";
import {StoreContext} from "../../../stores/StoreLoader";
import styles from "../styles/Header.module.scss"
import * as React from "react";
import UserDropDownMenu from "../UserDropDownMenu";

const SimpleAndFloating: React.FC<{
    toggleUserMenu: () => void,
    openLoginModal: () => void,
    mainUrl: string,
    hideNavigation?: boolean,
    hideLoginButton: boolean,
    preventTabbing: boolean,
}> = observer(props => {
    const {organizationStore, userStore, styleStore, interfaceStore} = useContext(StoreContext);

    const hideHeaderTitle = organizationStore.organization.json_data.settings.appearance.hideHeaderTitle;

    const logoContainerClassName = classNames({
        "sb-navbar-primary-element": true,
        "sb-navbar-primary-element-logo": true,
        "sb-navbar-logo": true,
        "notranslate": true,
    })
    const logoCellClassName = classNames({
        "sb-navbar-cell": true,
        "sb-navbar-logo-smallwidth": true,
    })
    const titleAndSocialCellClassName = classNames({
        "sb-navbar-cell": true,
        [styles.titleAndSocial]: true,
        [styles.titleAndSocialTitleInvisible]: hideHeaderTitle,
    })
    const primaryNavContainerClassName = classNames({
        "sb-navbar-primary-container": true,
        "clearfix": true,
        "sb-content-width-plus-margin": true,
    })
    const primaryNavClassName = classNames({
        "sb-primary-nav": true,
        "sb-navbar-background": true,
        "sb-organization-color-primary-nav": true,
    })
    const primaryNavElementCellClassName = classNames({
        "sb-navbar-primary-element": true,
        "sb-navbar-margin-top": true,
        [styles.inlineBlock]: true,
    })

    const userMenuButton = <button id="userMenuButton" onClick={props.toggleUserMenu} type="button"
                                   className="btn sb-organization-color-element-bg"
                                   style={{whiteSpace: "nowrap"}} aria-label="Open menu drawer"
                                   tabIndex={props.preventTabbing ? -1 : 0}>
        {userStore.avatar && <div className="sb-navbar-button-image"
                                  style={{backgroundImage: buildCssUrlString(userStore.avatar)}}/>}
        <div className="sb-navbar-button-text" aria-hidden="true">
            My Account
        </div>
    </button>

    return <nav id={"primaryNav"} className={primaryNavClassName} role="navigation">
        <div className={primaryNavContainerClassName}>
            <div className="sb-navbar-table">
                <div className="sb-navbar-row">
                    <div className={logoCellClassName} id={'logo-cell'}>
                        <div className={logoContainerClassName}>
                            {organizationStore.organization.top_nav_logo ?
                                <ClickableLink href={props.mainUrl} aria-hidden={hideHeaderTitle}
                                               title={organizationStore.organization.title}
                                               className={styleStore.themeID === "1" ? "sb-organization-font-color sb-home-link" : ""}
                                               tabIndex={props.preventTabbing ? -1 : 0}>
                                    <img className="sb-navbar-logo" src={organizationStore.organization.top_nav_logo}
                                         alt={organizationStore.organization.title}/>
                                </ClickableLink> : <div className="sb-navbar-logo"/>}
                        </div>
                    </div>
                    <div className={titleAndSocialCellClassName} id={'title-cell'}>
                        <div className="sb-navbar-primary-element"
                             style={{float: "left"}}>
                                <ClickableLink href={props.mainUrl}
                                               className="sb-organization-font-color sb-home-link notranslate"
                                               title={organizationStore.organization.title}
                                               tabIndex={props.preventTabbing || hideHeaderTitle ? -1 : 0}>
                                    <h1 className="sb-navbar-title"> {organizationStore.organization.title} </h1>
                                </ClickableLink>
                        </div>
                        <div id={"socialNavItems"} className="sb-navbar-primary-element"
                                              style={{paddingRight: "10px", float: "right"}}>
                            {organizationStore.organization.json_data?.settings?.social?.twitter &&
                                <ClickableLink
                                    href={organizationStore.organization.json_data.settings.social.twitter}
                                    className="sb-organization-font-color" target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Link to Twitter account"
                                    tabIndex={props.preventTabbing ? -1 : 0}
                                ><span
                                    className="fab fa-x-twitter sb-navbar-social-media" role="presentation"
                                    aria-hidden="true"/></ClickableLink>}
                            {organizationStore.organization.json_data?.settings?.social?.facebook &&
                                <ClickableLink
                                    href={organizationStore.organization.json_data.settings.social.facebook}
                                    className="sb-organization-font-color" target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Link to Facebook account"
                                    tabIndex={props.preventTabbing ? -1 : 0}
                                ><span
                                    className="fab fa-facebook-f sb-navbar-social-media" role="presentation"
                                    aria-hidden="true"/></ClickableLink>}
                            {organizationStore.organization.json_data?.settings?.social?.instagram &&
                                <ClickableLink
                                    href={organizationStore.organization.json_data.settings.social.instagram}
                                    className="sb-organization-font-color" target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Link to Instagram account"
                                    tabIndex={props.preventTabbing ? -1 : 0}
                                ><span
                                    className="fab fa-instagram sb-navbar-social-media" role="presentation"
                                    aria-hidden="true"/></ClickableLink>}
                            {organizationStore.organization.json_data?.settings?.social?.youtube &&
                                <ClickableLink
                                    href={organizationStore.organization.json_data.settings.social.youtube}
                                    className="sb-organization-font-color" target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Link to Youtube account"
                                    tabIndex={props.preventTabbing ? -1 : 0}
                                ><span
                                    className="fab fa-youtube sb-navbar-social-media" role="presentation"
                                    aria-hidden="true"/></ClickableLink>}
                            {organizationStore.organization.json_data?.settings?.social?.linkedin &&
                                <ClickableLink
                                    href={organizationStore.organization.json_data.settings.social.linkedin}
                                    className="sb-organization-font-color" target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Link to LinkedIn account"
                                    tabIndex={props.preventTabbing ? -1 : 0}
                                ><span
                                    className="fab fa-linkedin sb-navbar-social-media" role="presentation"
                                    aria-hidden="true"/></ClickableLink>}
                        </div>
                    </div>
                    {interfaceStore.breakpoint !== 'break-point-xs' && <div className="sb-navbar-cell" id={'search-cell'}>
                        <div className={primaryNavElementCellClassName}>
                            <ClickableLink
                                href={"/search"}
                                className="sb-organization-font-color"
                                rel="noopener noreferrer"
                                aria-label="Link to Search Page"
                                tabIndex={props.preventTabbing ? -1 : 0}
                            ><span
                                className="fas  fa-search sb-navbar-search-icon" role="presentation"
                                aria-hidden="true"/></ClickableLink>
                        </div>
                    </div>}
                    {!props.hideLoginButton &&
                        <div className="sb-navbar-cell" style={{whiteSpace: "nowrap"}} id={"loginUserMenuButtons"}>
                            <div className="sb-navbar-primary-element" style={{marginLeft: "1em", textAlign: "right"}}>
                                {userStore.id ?
                                    <UserDropDownMenu userMenuButton={userMenuButton}/>
                                     :
                                    <button id="loginMenuButton" className="btn sb-organization-color-element-bg"
                                            onClick={props.openLoginModal}>
                                        <span className="sb-navbar-button-text">Login</span>
                                    </button>}
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    </nav>
});

export default SimpleAndFloating;

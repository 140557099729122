import React, {useContext, useEffect, useRef} from "react";
import classNames from "classnames";
import {UserController} from "../../backends/YiiRoutes";
import {withoutLocaleInURI} from "../../internationalization/i18nURI";
import {StoreContext} from "../../stores/StoreLoader";
import URI from 'urijs';
import ClickableLink from "../utilities/ClickableLink";
import styles from "./styles/UserDropDownMenu.module.scss";
import {observer} from "mobx-react";
import {LogoutButton} from "../admin/sidebar/items/LogoutButton";
import {isSchoolBlocksApp} from "../../utils/SchoolBlocksUtilities";
import useClickOutside from "../../hooks/useClickOutside";


export interface UserMenuDropDownItem {
    path: string,
    title: string,
    icon: string,
}
const UserMenuDropDown = observer((props: {
    userMenuButton: JSX.Element,
}) => {
    const {userStore, interfaceStore, i18nStore} = useContext(StoreContext);

    const params = {
        "following":true,
    }

    function onClick() {
        if (interfaceStore.userMenuOpen) {
            interfaceStore.toggleUserMenu()
        }
    }

    const dropdownRef = useClickOutside(onClick);

    const dropDownContainerClassName = classNames({
        [styles.container]: true,
        [styles.containerOpen]: interfaceStore.userMenuOpen,
    })

    let links: UserMenuDropDownItem[];

    if (isSchoolBlocksApp()) {
        links = [
            {
                path: UserController.myCalendar(i18nStore.currentOrgSupportedLocale),
                title: "My Calendar",
                icon: "far fa-calendar",
            },
            {
                path: new URI(withoutLocaleInURI("/schoolfeed")).setSearch(params).toString(),
                title: "My SchoolFeed",
                icon: "fas fa-rss",
            },
            {
                path: UserController.settings(i18nStore.currentOrgSupportedLocale),
                title: "My Information",
                icon: "fas fa-cog",
            },

        ]
    } else {
        links = [
            {
                path: new URI(withoutLocaleInURI("/schoolfeed")).setSearch(params).toString(),
                title: "My Feed",
                icon: "fas fa-rss",
            },
            {
                path: "/",
                title: "My Friends",
                icon: "fas fa-users",
            },
            {
                path: "/",
                title: "My Town(s)",
                icon: "fas fa-house",
            },
            {
                path: "/",
                title: "My Topics",
                icon: "fas fa-lightbulb",
            },
            {
                path: "/",
                title: "My Information",
                icon: "fas fa-cog",
            },
            {
                path: "/search",
                title: "Search",
                icon: "fas fa-magnifying-glass",
            },
        ]
    }

    if (userStore.isAdmin) {
        links.splice(4, 0, {
            path: "/cms/admin/organizationSettings",
            title: "Configuration",
            icon: "fas fa-cog",
        })
    }
    if (userStore.isEditor) {
        links.splice(4, 0, {
            path: new URI(withoutLocaleInURI("/dashboard")).toString(),
            title: "My Dashboard",
            icon: "fas fa-pencil",
        })
    }

    const focusCloseButton = () => {
        const closeButton = document.getElementById(`userMenuButton`) as HTMLButtonElement;
        if (closeButton) closeButton.focus();
    }

    const focusFirstButton = () => {
        const firstButton = document.getElementById('userMenu-firstbutton') as HTMLButtonElement;
        if (firstButton) firstButton.focus();
    }

    const handleKeydown = (evt) => {
        if (evt.code === "Escape") {
            focusCloseButton()
            interfaceStore.toggleUserMenu()
        }
    }
    useEffect(() => {
        if (interfaceStore.userMenuOpen) {
            window.addEventListener('keydown', handleKeydown);
            return () => {
                window.removeEventListener('keydown', handleKeydown);
            }
        }
    }, [interfaceStore.userMenuOpen]);

    return (
        <div ref={dropdownRef} className={styles.userMenu}>
            {props.userMenuButton}
            <div className={dropDownContainerClassName}>
                <ul>
                    {links.map((link, i) => {
                        const isCurrentPath = URI(interfaceStore.currentFullUrl).path() === link.path;

                        return <li key={link.title}>
                            <ClickableLink href={link.path}
                                           id={i === 0 ? 'userMenu-firstbutton' : undefined}
                                           tabIndex={interfaceStore.userMenuOpen ? 0 : -1}
                                           type="button"
                                           onClick={onClick}
                                           className={`${isCurrentPath ? "sb-organization-color-element-outline" : ""}`}>
                                <div>
                                    <span className={link.icon}/>
                                </div>
                                <span>{link.title}</span>
                            </ClickableLink>
                        </li>
                    })}
                    <li>
                        <LogoutButton tabIndex={interfaceStore.userMenuOpen ? 0 : -1}>
                            <div>
                                <span className={'fas fa-times'}/>
                            </div>
                            <span>Logout</span>
                        </LogoutButton>
                    </li>
                    <span tabIndex={interfaceStore.userMenuOpen ? 0 : -1}
                          onFocus={focusFirstButton}
                    />
                </ul>
            </div>
        </div>
    )
});
export default UserMenuDropDown;
